<template>
  <div>
    <!--Header-->
    <section id="home">
      <b-container class="contents-container">
        <b-row class="h-100 home-header justify-content-center align-items-center">
          <b-col md="12" lg="6" xl="5" class="home-contents mb-4">
            <h1 class="display-1 title">{{ localeStrings.title }}</h1>
            <p class="subtitle">
              {{localeStrings.intro1}}
            </p>
            <p class="subtitle">
              {{localeStrings.intro2}}
            </p>
    
            <b-button :href="appURL" variant="success" size="lg" class="mt-5" style="font-size : 18px">
              {{ localeStrings.start}}
            </b-button>
            <div class="mt-3">
              <a href="https://www.tensorflow.org/" target="_blank" rel="noreferrer">
                <img class="logo-tf" src="@/assets/images/tf_logo.svg" alt="" />
              </a>
              <a href="https://ml5js.org/" target="_blank" rel="noreferrer">
                <img class="logo-ml5" src="@/assets/images/ml5_logo.png" alt="" />
              </a>
            </div>
          </b-col>
          <b-col md="12" lg="6" xl="7" class="home-image">
            <img src="@/assets/images/mainImage.jpg" alt="" class="w-100" />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- Page content -->
    <section class="py-3 text-center mb-5">
      <h2 class="display-3">{{ localeStrings.contentTitle }}</h2>
    </section>
    
    <section>
      <b-container class="contents-container">
        <div id="pages" class="section-marker">
          <h2>{{ localeStrings.pageShareTitle }}</h2>
          <p>
            {{ localeStrings.pageShareDescription }}
          </p>
          <p v-if="sharedPages.length < 1">
            {{ localeStrings.noData }}
          </p>
        
          <contents-swiper :loop="false" :slidesPerView="1" :breakpoints="slideBreakpoints" class="mx-0">
            <template #slides>
              <swiper-slide v-for="(sharedPage) in sharedPages" :key="sharedPage.deploymentURL">
                <b-card no-body>
                  <b-card-img :height="thumbnailSize" :src="sharedPage.thumbnail || defaultImage" top class="card-thumbnail"></b-card-img>
                  <b-card-body>
                    <b-card-title>
                      {{sharedPage.workspace}}
                    </b-card-title>
                    <b-card-text class="text-truncate">
                      {{sharedPage.description}}
                    </b-card-text>
                    <b-card-text class="card-link link-text" @click="openLink(sharedPage.deploymentURL, $event)">{{ localeStrings.goToPage }}</b-card-text>
                  </b-card-body>
                </b-card>
              </swiper-slide>
            </template>
          </contents-swiper>
        </div>
          
          <div id="samples" class="section-marker">
            <h2>{{ localeStrings.samplesTitle }}</h2>
            <p>{{ localeStrings.samplesDescription }}</p>
            <p v-if="samplePages.length < 1">
              {{ localeStrings.noData }}
            </p>
            <contents-swiper :loop="false" :slidesPerView="1" :breakpoints="slideBreakpoints" class="mx-0">
              <template #slides>
                <swiper-slide v-for="({descriptions, model, icon, image, title, deploymentURL}) in samplePages" :key="model">
                  <b-card no-body :title="model || title">
                    <b-card-img :height="thumbnailSize" :src="image || defaultImage" top class="card-thumbnail"></b-card-img>
                    <b-card-body>
                      <b-card-title>
                        {{model || title}}
                      </b-card-title>
                      <b-card-text class="text-truncate">
                        {{descriptions}}
                      </b-card-text>
                      <b-card-text class="card-link link-text" @click="openLink(deploymentURL, $event)">{{ localeStrings.goToPage }}</b-card-text>
                      <div class="text-right" v-if="icons[icon]">
                        <img :src="icons[icon].image" :class="`logo-${icon}`" @click="openLink(icons[icon].link, $event)">
                      </div>
                    </b-card-body>
                  </b-card>
                </swiper-slide>
              </template>
            </contents-swiper>
          </div>
        <hr />
    
        <div id="projects" class="section-marker">
          <h2>{{ localeStrings.projectShareTitle }}</h2>
          <p>{{ localeStrings.projectShareDescription }}</p>
    
          <p v-if="sharedProjects.length < 1">
            {{ localeStrings.noData }}
          </p>
    
          <contents-swiper :loop="false" :slidesPerView="1" :breakpoints="slideBreakpoints" class="mx-0">
            <template #slides>
              <swiper-slide v-for="project in sharedProjects" :key="`${project.id}/${project.workspace}`">
                <b-card no-body>
                  <b-card-img :height="thumbnailSize" :src="project.thumbnail || defaultImage" top class="card-thumbnail"></b-card-img>
                  <b-card-body>
                    <b-card-title>
                      {{project.workspace}}
                    </b-card-title>
                    <b-card-sub-title class="mb-2">{{project.workspaceType}}</b-card-sub-title>
                    <b-card-text class="text-truncate">
                      {{project.description}}
                    </b-card-text>
                    <div class="text-right">
                      <b-button size="sm" variant="primary" @click="cloneProject(project)">{{ localeStrings.copyProject }}</b-button>
                    </div>
                  </b-card-body>
                </b-card>
              </swiper-slide>
            </template>
          </contents-swiper>
        </div>
    
        <div id="tutorials" class="section-marker">
          <h2>{{ localeStrings.tutorialTitle }}</h2>
          <p>
            {{ localeStrings.tutorialDescription }}
          </p>
          <p v-if="sampleProjects.length < 1">
            {{ localeStrings.noData }}
          </p>
          <contents-swiper :loop="false" :slidesPerView="1" :breakpoints="slideBreakpoints" class="mx-0 mb-5">
            <template #slides>
              <swiper-slide v-for="project in sampleProjects" :key="project.tutorial">
                <b-card no-body>
                  <b-card-img :height="thumbnailSize" :src="project.thumbnail || defaultImage" top class="card-thumbnail"></b-card-img>
                  <b-card-body>
                    <b-card-title>
                      {{project.title}}
                    </b-card-title>
                    <b-card-sub-title class="mb-2">{{project.workspaceType}}</b-card-sub-title>
                    <b-card-text class="text-truncate">
                      {{project.description}}
                    </b-card-text>
                    <div class="text-right">
                      <b-button size="sm" variant="primary" @click="cloneProject(project)">{{ localeStrings.copyProject }}</b-button>
                    </div>
                  </b-card-body>
                </b-card>
              </swiper-slide>
            </template>
          </contents-swiper>
        </div>
    
      </b-container>
    
    </section>
  </div>
</template>
<style scoped>
  .section-marker {
    margin-top: -350px;
    padding-top: 350px;
  }

  @media (min-width : 992px) {
    .section-marker {
      margin-top: -120px;
      padding-top: 120px;
    }
  }

  .home-header {
    position: relative;
    min-height: 100vh;
    align-content: center;
  }

  .contents-container {
    min-width: 80vw;
  }

  .title {
    font-weight: 500;
    word-break : keep-all;
  }

  .subtitle {
    font-size: 18px;
    color: #3b3d50;
    font-weight: 400;
  }

  .logo-tf {
    height: 40px;
  }

  .logo-ml5 {
    height: 25px;
  }

  .card-thumbnail {
    object-fit: cover;
  }

  .link-text {
    cursor: pointer;
  }

  .link-text:hover {
    color: #2dce89;
  }
</style>

<script>
  import defaultImage from 'assets/images/machine-learning.png';
  import tf_logo from '@/assets/images/tf_logo.svg'
  import ml5_logo from '@/assets/images/ml5_logo.png'

  import samplePages from './samplePages.js';
  import sampleProjects from './sampleProjects.js';

  import ContentsSwiper from '@/components/ContentsSwiper.vue'
  import { SwiperSlide } from "vue-awesome-swiper";

  import { listSharedWorkspace, listPageDeployments } from '@/apis/shareServiceAPI.js';

  const appURL = "https://app.nocodingai.com/";
  
  export default {
    name: 'home-page',
    components: { ContentsSwiper, SwiperSlide },
    async mounted() {
      this.sharedProjects = await listSharedWorkspace();
      this.sharedPages = await listPageDeployments();
    },
    data() {
      const localeStrings = this.getLocaledString();
      return {
        defaultImage,
        localeStrings,
        sharedPages: [],
        samplePages,
        sharedProjects: [],
        sampleProjects,
        slideBreakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        },
        icons: {
          tf: {
            link: 'https://www.tensorflow.org/',
            image: tf_logo
          },
          ml5: {
            link: "https://ml5js.org/",
            image: ml5_logo
          }
        },
        thumbnailSize: 250,
        appURL
      };
    },
    methods: {
      openLink(link, event) {
        event.stopPropagation();
        window.open(link);
      },
      cloneProject({ id, workspace, tutorial }) {
        const targetURL = new URL(appURL);

        const queryStrings = targetURL.searchParams;

        if (tutorial) {
          queryStrings.append('tutorial', tutorial);
        }
        else {
          queryStrings.append('sourceId', id);
          queryStrings.append('sourceWorkspace', workspace);
        }

        window.open(targetURL);
      }
    }
  };
</script>
