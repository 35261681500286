<template>
  <swiper :options="options" ref="swiper" @slideChange="$emit('slideChange')">
      <slot name="slides"></slot>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-scrollbar" v-if="!!scrollbar" slot="scrollbar"></div>
      <b-button class="prev-button text-dark p-0 ml-2" v-if="!!navigation" slot="button-prev" variant="link"><b-icon icon="chevron-left"></b-icon></b-button>
      <b-button class="next-button text-dark p-0 mr-2" v-if="!!navigation" slot="button-next" variant="link"><b-icon icon="chevron-right"></b-icon></b-button>
  </swiper>
</template>

<style scoped>
    .prev-button,
    .next-button {
        position: absolute;
        top: calc(50% - 0.75rem);
        z-index: 100;
        font-size : 1.5rem;
        opacity: 0.5;
    }

    .prev-button:hover,
    .next-button:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.5)
    }

    .prev-button {
        left: 0;
    }

    .next-button {
        right: 0;
    }
</style>

<script>
    import { Swiper } from "vue-awesome-swiper";
    import 'swiper/css/swiper.css';

    export default {
        components: {
            Swiper,
        },
        props: {
            slidesPerView: {
                type: Number,
                default: 2
            },
            spaceBetween: {
                type: Number,
                default: 30
            },
            breakpoints: {
                type: Object,
                default: () => ({
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 0
                    }
                }),
            },
            loop: {
                type: Boolean,
                default: true
            },

            pagination: {
                type: [Boolean, Object],
                default: () => ({
                    el: '.swiper-pagination',
                    clickable: true
                })
            },
            navigation: {
                type: [Boolean, Object],
                default: () => ({
                    nextEl: '.next-button',
                    prevEl: '.prev-button'
                })
            },
            scrollbar: {
                type: Boolean,
                default: false
            },
            otherOptions: {
                type: Object,
                default: () => ({})
            }

        },
        data() {
            return {
                options: {
                    slidesPerView: this.slidesPerView,
                    spaceBetween: this.spaceBetween,
                    loop: this.loop,
                    breakpoints: this.breakpoints,
                    pagination: this.pagination,
                    navigation: this.navigation,
                    scrollbar: this.scrollbar && { el: '.swiper-scrollbar' },
                    ...this.otherOptions
                }

            }
        },
        computed: {
            swiper() {
                return this.$refs.swiper.$swiper;
            }
        }
    }
</script>
