const ENDPOINT = 'https://b2hwlcefn4.execute-api.ap-northeast-2.amazonaws.com/default';

const NETWORKERROR = new Error('Network Error');

/*
    공유된 워크스페이스 목록 조회
    GET ENDPOINT
*/
export const listSharedWorkspace = async() => {

    const response = await fetch(ENDPOINT);

    if (response.ok) {
        return await response.json();
    }
    else {
        throw NETWORKERROR;
    }
};

/*
    공유된 페이지 목록 조회
    GET ENDPOINT/page-deployment
*/
export const listPageDeployments = async() => {

    const response = await fetch(`${ENDPOINT}/page-deployment`);

    if (response.ok) {
        return await response.json();
    }
    else {
        throw NETWORKERROR;
    }
};
