import IrisImage from '@/assets/images/Iris.png'
import CarImage from '@/assets/images/CarTutorial.jpg'
import MNISTImage from '@/assets/images/MNIST.png'
import PitchImage from '@/assets/images/PitchTutorial.png'
import WineImage from '@/assets/images/WineTutorial.jpg'
import PimaImage from '@/assets/images/PimaTutorial.jpg'
import HousingImage from '@/assets/images/BostonTutorial.jpg'
import CifarImage from '@/assets/images/CifarTutorial.png'
import XrayImage from '@/assets/images/XrayTutorial.jpg'

import { getLocaledString } from '@/plugins/locale.js'

const localeStrings = getLocaledString();

export default [{
        workspaceType: "trainManager",
        title: localeStrings.irisProjectTitle,
        tutorial: "iris",
        thumbnail: IrisImage,
        description: localeStrings.irisProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.carProjectTitle,
        tutorial: "car",
        thumbnail: CarImage,
        description: localeStrings.carProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.mnistProjectTitle,
        tutorial: "mnist",
        thumbnail: MNISTImage,
        description: localeStrings.mnistProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.pitchProjectTitle,
        tutorial: "pitch",
        thumbnail: PitchImage,
        description: localeStrings.pitchProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.mnistTransferProjectTitle,
        tutorial: "mnistTransfer",
        thumbnail: MNISTImage,
        description: localeStrings.mnistTransferProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.wineProjectTitle,
        tutorial: "wine",
        thumbnail: WineImage,
        description: localeStrings.wineProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.pimaProjectTitle,
        tutorial: "pima",
        thumbnail: PimaImage,
        description: localeStrings.pimaProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.housingProjectTitle,
        tutorial: "housing",
        thumbnail: HousingImage,
        description: localeStrings.housingProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.xrayProjectTitle,
        tutorial: "xray",
        thumbnail: XrayImage,
        description: localeStrings.xrayProjectDescription,
    },
    {
        workspaceType: "trainManager",
        title: localeStrings.cifarProjectTitle,
        tutorial: "cifar",
        thumbnail: CifarImage,
        description: localeStrings.cifarProjectDescription,
    },
];
