<template>
    <b-navbar toggleable="lg" sticky class="bg-white container nav-container">
        <b-navbar-brand href="#home">
                <h1 class="display-3">nocodingAI</h1>
            </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
     
         <b-collapse id="nav-collapse" is-nav>
             <b-navbar-nav class="ml-auto">
                 <b-nav-item href="#pages"><b-button variant="link" class="text-dark">{{localeStrings.pageShareMenu}}</b-button></b-nav-item>
                 <b-nav-item href="#samples"><b-button variant="link" class="text-dark">{{localeStrings.samplesTitle}}</b-button></b-nav-item>
                 <b-nav-item href="#projects"><b-button variant="link" class="text-dark">{{localeStrings.projectShareMenu}}</b-button></b-nav-item>
                 <b-nav-item href="#tutorials"><b-button variant="link" class="text-dark">{{localeStrings.tutorialTitle}}</b-button></b-nav-item>
                 <b-nav-item href="https://app.nocodingai.com/" class="px-2"><b-button variant="success">{{localeStrings.signIn}}</b-button></b-nav-item>
             </b-navbar-nav>
         </b-collapse>
     
         
    </b-navbar>
</template>

<style>
    .nav-container{
        min-width : 80vw;
    }
</style>
<script>
    export default {
        data(){
            const localeStrings = this.getLocaledString();
            return {
                localeStrings
            }
        }
    }
</script>