const locales = [
    {key : 'start', ko : '무료로 시작하기', en : 'Get Started for free'},
    {key : 'noData', ko : '데이터가 없습니다.', en : 'No Data'},
    {key : 'goToPage', ko : '클릭하면 페이지로 이동', en : 'Go to page'},
    {key : 'copyProject', ko : '프로젝트 복사하기', en : 'Copy Project'},
    {key : 'title', ko : '지금 바로 AI 개발자가 되어보세요.', en : 'No More Codes.'},
    {key : 'intro1', ko : '코딩 없는 AI 플랫폼, nocodingAI에 오신 것을 환영합니다.', en : 'Join and explore the community of the best no coding AI makers and experts.'},
    {key : 'intro2', ko : 'nocodingAI와 함께 AI 전문가가 되어보세요!', en : 'You can make your own AI applications without any code in nocodingAI.'},
    {key : 'contentTitle', ko : 'nocodingAI 살펴보기', en : 'Browse nocodingAI'},
    {key : 'pageShareMenu', ko : '배포된 페이지', en : 'Deployed Pages'},
    {key : 'pageShareTitle', ko : '새로 공유된 배포 페이지', en : 'Recently deployed pages'},
    {key : 'pageShareDescription', ko : '훈련 시킨 모델을 나만의 웹페이지로 만들어 배포할 수 있습니다. 다른 사용자들이 공유한 페이지를 살펴보세요!', en : 'Train a AI model and make it a webpage! And also check out these recently shared pages.'},
    {key : 'samplesTitle', ko : '인공지능 체험', en : 'AI Samples'},
    {key : 'samplesDescription', ko : '다양한 인공지능 모델을 직접 체험해 보세요.', en : 'Experience Various AI models with these samples.'},
    {key : 'projectShareMenu', ko : '프로젝트', en : 'Projects'},
    {key : 'projectShareTitle', ko : '새로 공유된 프로젝트', en : 'Recently Shared Projects'},
    {key : 'projectShareDescription', ko : '내가 만든 프로젝트를 공유할 수 있습니다. 다른 사용자가 공유한 데이터와 모델을 사용해 나만의 모델로 훈련시켜 보세요.', en : 'You can share your project with others. Train your own model from shared projects!'},
    {key : 'tutorialTitle', ko : '따라해보기', en : 'Tutorials'},
    {key : 'tutorialDescription', ko : 'nocodingAI에서 제공하는 튜토리얼 프로젝트입니다. 직접 따라해보면서 인공지능을 만들어 봅시다.', en : 'nocodingAI provides tutorial projects. Make your own AI following tutorials.'},
    {key : 'signIn', ko : '로그인', en : 'Sign In'},
    {key : 'irisProjectTitle', ko : '붓꽃 품종 분류 모델 훈련', en : 'Model training for classifying varieties of irises'},
    {key : 'irisProjectDescription', ko : '이 붓꽃은 어떤 품종일까요?', en : 'Train Iris Classfication model'},
    {key : 'carProjectTitle', ko : '자동차 정보로 연비 예측하기', en : 'Predicting fuel efficiency of cars'},
    {key : 'carProjectDescription', ko : '자동차의 연비를 예측해봅시다.', en : 'Predict Miles per gallon using car dataset'},
    {key : 'mnistProjectTitle', ko : '손글씨 숫자 인식하기', en : 'Recognizing handwritten numbers'},
    {key : 'mnistProjectDescription', ko : '손으로 쓴 숫자를 인식합니다.', en : 'Recognize handwritten numbers using MNIST dataset'},
    {key : 'pitchProjectTitle', ko : '야구 투구 유형 예측하기', en : 'Predicting baseball pitch types'},
    {key : 'pitchProjectDescription', ko : '투구 구종을 분석합니다.', en : 'Predict pitch types'},
    {key : 'mnistTransferProjectTitle', ko : 'MNIST 모델 전이 학습시키기', en : 'Transfer learning MNIST Model'},
    {key : 'mnistTransferProjectDescription', ko : 'MNIST 모델 전이 학습시키기 튜토리얼입니다.', en : 'MNIST Transfer learning'},
    {key : 'wineProjectTitle', ko : '와인 분류 모델 훈련하기', en : 'Training the wine classification model'},
    {key : 'wineProjectDescription', ko : '와인 분류 모델 훈련하기 튜토리얼입니다.', en : 'Classify Wines'},
    {key : 'pimaProjectTitle', ko : '피머(Pima) 부족 인디언 당뇨병 예측하기', en : 'Prediction of Pima Indian Diabetes'},
    {key : 'pimaProjectDescription', ko : '피머(Pima) 부족 인디언 당뇨병 예측하기 튜토리얼입니다.', en : 'Pima Indian diabetes'},
    {key : 'housingProjectTitle', ko : '보스턴 집값 예측', en : 'Boston house price forecast'},
    {key : 'housingProjectDescription', ko : '보스턴 집값 예측 튜토리얼입니다.', en : 'Boston Housing price'},
    {key : 'cifarProjectTitle', ko : 'CIFAR(캐나다 고등연구소) 이미지 데이터셋을 이용한 이미지 분류', en : 'Image Classfication using CIFAR(Canadian Institute For Advanced Research) datasets'},
    {key : 'cifarProjectDescription', ko : 'CIFAR(캐나다 고등연구소) 이미지 데이터셋을 이용한 이미지 분류', en : 'Image Classfication using CIFAR(Canadian Institute For Advanced Research) datasets'},
    {key : 'xrayProjectTitle', ko : '흉부 X-ray 이미지를 통한 폐 이상 분류', en : 'Detect pneumonia using chest x-ray images'},
    {key : 'xrayProjectDescription', ko : '흉부 X-ray 이미지를 통한 폐 이상 분류', en : 'Detect pneumonia using chest x-ray images'},
];

const locale = (window.navigator.language || window.navigator.userLanguage).substring(0,2);

export const getLocaledString = (language = locale) => locales.reduce((acc, { key, ...languages }) => ({ ...acc, [key]: languages[language] }), {});

export default {
    install(Vue){
        Vue.prototype.getLocaledString = getLocaledString;
    }
}