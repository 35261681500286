<template>
  <div id="app">
    <NavBar></NavBar>
    <Home></Home>
  </div>
</template>

<script>
  import Home from './views/pages/Home.vue'
  import NavBar from '@/components/NavBar.vue'

  export default {
    name: 'App',
    components: {
      Home,
      NavBar
    }
  }
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
  /*#app {*/

  /*  font-family: Avenir, Helvetica, Arial, sans-serif;*/

  /*  -webkit-font-smoothing: antialiased;*/

  /*  -moz-osx-font-smoothing: grayscale;*/

  /*  text-align: center;*/

  /*  color: #2c3e50;*/

  /*  margin-top: 60px;*/

  /*}*/
</style>
