import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import '@/assets/sass/argon.scss';
import '@/assets/css/nucleo/css/nucleo.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import locale from '@/plugins/locale.js';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(locale);


new Vue({
    render: h => h(App),
}).$mount('#app')
