import MNISTImage from '@/assets/images/MNIST.png'
import IrisImage from '@/assets/images/Iris.png'
import FaceImage from '@/assets/images/FaceDetection.png'
import GANImage from '@/assets/images/GANCartoon.png'
import HandImage from '@/assets/images/HandPose.png'
import COCOSSDImage from '@/assets/images/COCOSSD.png'
import PosenetImage from '@/assets/images/PoseNet.png'
import MobilenetImage from '@/assets/images/Mobilenet.png'
import DoodlenetImage from '@/assets/images/DoodleNet.png'
import PixCatImage from '@/assets/images/PIXCAT.png'
import PixFaceImage from '@/assets/images/PIXFACE.png'
import UdnieImage from '@/assets/images/udnie.jpg'
import WaveImage from '@/assets/images/wave.jpg'
import BodypixImage from '@/assets/images/bodypix.png'

export default [{
        model: 'IRIS',
        descriptions: "iris",
        image: IrisImage,
        deploymentURL: "https://samples.nocodingai.com/IRIS",
        icon: "tf"
    },
    {
        model: 'MNIST',
        descriptions: "mnist",
        image: MNISTImage,
        deploymentURL: "https://samples.nocodingai.com/MNIST",
        icon: "tf"
    },
    {
        model: 'FACE',
        descriptions: "face",
        image: FaceImage,
        deploymentURL: "https://samples.nocodingai.com/FACE",
        icon: "tf"
    },
    {
        model: 'GAN',
        descriptions: "GAN",
        image: GANImage,
        deploymentURL: "https://samples.nocodingai.com/GAN",
        icon: "tf"
    },
    {
        model: 'HAND',
        descriptions: "HAND",
        image: HandImage,
        deploymentURL: "https://samples.nocodingai.com/HAND",
        icon: "tf"
    },
    {
        model: 'COCOSSD',
        descriptions: "COCOSSD",
        image: COCOSSDImage,
        deploymentURL: "https://samples.nocodingai.com/COCOSSD",
        icon: "tf"
    },
    {
        model: 'POSENET',
        descriptions: "POSE",
        image: PosenetImage,
        deploymentURL: "https://samples.nocodingai.com/POSENET",
        icon: "tf"
    },
    {
        model: 'MOBILENET',
        descriptions: "MOBILENET",
        image: MobilenetImage,
        deploymentURL: "https://samples.nocodingai.com/MOBILENET",
        icon: "ml5"
    },
    {
        model: 'DOODLENET',
        descriptions: "DOODLENET",
        image: DoodlenetImage,
        deploymentURL: "https://samples.nocodingai.com/DOODLENET",
        icon: "ml5"
    },
    {
        model: 'PIXCAT',
        descriptions: "PIXCAT",
        image: PixCatImage,
        deploymentURL: "https://samples.nocodingai.com/PIXCAT",
        icon: "tf"
    },
    {
        model: 'PIXFACE',
        descriptions: "PIXFACE",
        image: PixFaceImage,
        deploymentURL: "https://samples.nocodingai.com/PIXFACE",
        icon: "tf"
    },
    {
        model: 'BODYPIX',
        descriptions: "BODYPIX",
        image: BodypixImage,
        deploymentURL: "https://samples.nocodingai.com/BODYPIX",
        icon: "ml5"
    },
    {
        model: 'STYLEUDNIE',
        descriptions: 'STYLEUDNIE',
        image: UdnieImage,
        deploymentURL: "https://samples.nocodingai.com/STYLEUDNIE",
        icon: "tf"
    },
    {
        model: 'STYLEWAVE',
        descriptions: 'STYLEWAVE',
        image: WaveImage,
        deploymentURL: "https://samples.nocodingai.com/STYLEWAVE",
        icon: "tf"
    }
];